import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function Header() {
    return (
        <header id="header">
  <div className="container">
    <div className="header-row">
      <div className="header-column justify-content-start">
        <div className="logo me-2 me-lg-3"> <a href="index.html" className="d-flex" title="Quickai - HTML Template"><img src="/images/logo.png" alt="HOTEL IGLOOS" style={{ maxHeight: "100px", maxHeight: "100px" }}></img></a> </div>
      </div>
      <div className="header-column justify-content-end"> 
        {/* Primary Navigation
    ============================================= */}
        <nav className="primary-menu navbar navbar-expand-lg">
          <div id="header-nav" className="collapse navbar-collapse">
            <ul className="navbar-nav">
            <li><Link to={"/"} className="dropdown-item" href="index-11.html">Home</Link></li>
            <li><Link to={"/booking"} className="dropdown-item" href="index-11.html">Booking</Link></li>
            <li><Link to={"/aboutus"} className="dropdown-item" href="index-11.html">About us</Link></li>
            <li><Link to={"/contactus"} className="dropdown-item" href="index-11.html">Contact us</Link></li>
            </ul>
          </div>
        </nav>
        {/* Primary Navigation end */} 
        {/* Collapse Button
		  =============================== */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-nav"> <span /> <span /> <span /> </button>
        <div className="vr mx-2 h-25 my-auto" />
        {/* Login Signup
		  =============================== */}
        <nav className="login-signup navbar navbar-expand">
          <ul className="navbar-nav">
            <li className="profile"><a className="pe-0" data-bs-toggle="modal" data-bs-target="#login-modal" href="#" title="Login / Sign up"><span className="d-none d-sm-inline-block"></span></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>

  
    );
}