import { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Footer from "./Footer";
const initialFieldValues = {
    bookingId: "00000000-0000-0000-0000-000000000000",
    reservationNo: "",
    checkinDate: new Date(),
    checkoutDate: new Date(),
    noOfNights: 1,
    adults: 1,
    kids: 0,
    noOfRooms: 0,
    guestName: "",
    guestEmail: "",
    guestMobile: "",
    roomPrices: 0,
    roomGST: 0,
    totalRoomPrice: 0,
    extraCharges: 0,
    foodBill: 0,
    discount: 0,
    discountAmount: 0,
    price: 0,
    gSTAmount: 0,
    gST: 12,
    finalPrice: 0,
    bookingDate: new Date(),
    bookingStatus: "BOOKED",
    paymentStatus: "PENDING",
    paymentMode: "CASH",
    source: "WALKIN",
    pendingAmount: 0,
    paidAmount: 0
};
export default function Home() {
    return (
        <div id="main-wrapper">
            <section class="igloos_hero">
                <img class="igloos_logo" src="/images/logo.png" alt="logo" />
                <Link to={"/booking"} className="igloos_bookNow btn">Book Now</Link>
                <img
                    class="igloos_hero_bg"
                    src="/images/HomePic.jpeg"
                    alt="igloos_hero_bg"
                />
                <p class="igloos_HeroTitle">SIMPLE STAYS UNFORGETTABLE DAYS</p>
            </section>
            <div id="content">
                <section class="igloos_about">
                    <div class="igloos_about_leftSide">
                        <h5 class="igloos_about_ls_head">ABOUT US</h5>
                        <h1 class="igloos_about_ls_title">At Hotel Igloos</h1>
                        <div>
                            <p class="igloos_about_ls_paras">
                                Get ready for personalized service and cool vacations. Our team
                                wants to make your time with us super special, whether it's helping
                                you out or planning fun holiday deals.
                            </p>
                            <p class="igloos_about_ls_paras">
                                Offering a warm and inviting atmosphere, Hoteligloos is dedicated to
                                ensuring your stay is memorable. Our passion for affordable comfort
                                creates a cozy retreat for every guest. Explore a unique blend of
                                affordability and charm at Hoteligloos, where simplicity meets
                                hospitality. Nestled as
                                <strong> SURAAG HOTELS AND RESORTS</strong> near Hanuman Sai Nagar,
                                Beside Sri KRISHNA Theater.
                            </p>
                            <p>
                                Hotel Igloos, where luxury meets heritage and hospitality! Nestled in the heart of India, we pride ourselves on offering an unforgettable experience that blends modern comfort with traditional Indian warmth. Get ready for personalized service and cool vacations. Our team wants to make your time with us super special, whether it's helping you out or planning fun holiday deals.
                                Offering a warm and inviting atmosphere, Hoteligloos is dedicated to ensuring your stay is memorable. Our passion for affordable comfort creates a cozy retreat for every guest. Explore a unique blend of affordability and charm at Hoteligloos, where simplicity meets hospitality. Nestled as SURAAG HOTELS AND RESORTS near Hanuman Sai Nagar, Beside Sri KRISHNA Theater.
                            </p>
                        </div>
                    </div>
                    <div class="igloos_about_rightSide">
                        <img
                            class="igloos_about_img1"
                            src="./images/reception.jpg"
                            alt="hotelInside"
                        />
                    </div>
                </section>
                <div class="igloos_home_img3"></div>
                <div className="container">
                    <section className="section">
                        <div className="container">
                            <h2 className="text-9 fw-500 text-center">Popular Rooms</h2>
                            <p className="lead text-center mb-4">Choose Best Room's  as per your confort</p>
                            <div className="row g-4 banner">
                                <div className="col-md-8">
                                    <div className="item rounded h-100"> <Link to={"/booking"}>
                                        <div className="caption text-center">
                                            <h2 className="text-7">Suite</h2>
                                            <p>Price starts from 4000 INR</p>
                                        </div>
                                        <div className="banner-mask" />
                                        <img className="img-fluid h-100" src="images/rooms/suite/8.jpg" alt="hotels" /> </Link> </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <div className="item rounded"> <Link to={"/booking"}>
                                                <div className="caption text-center">
                                                    <h2>Premium</h2>
                                                    <p>Price starts from 2500 INR</p>
                                                </div>
                                                <div className="banner-mask" />
                                                <img className="img-fluid" src="images/rooms/premium/1.jpg" alt="hotels" /> </Link> </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="item rounded"> <Link to={"/booking"}>
                                                <div className="caption text-center">
                                                    <h2>Executive</h2>
                                                    <p>Price starts from 1625 INR</p>
                                                </div>
                                                <div className="banner-mask" />
                                                <img className="img-fluid" src="images/rooms/executive/1.jpg" alt="hotels" /> </Link> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-grid pt-4"> <Link to={"/booking"} className="btn btn-outline-primary shadow-none">More Rooms</Link> </div>
                        </div>
                    </section>
                    <div className="bg-white shadow-md rounded p-4">
                    <section class="igloos_facilities">
      <h5 class="igloos_facilities_head">HOTEL IGLOOS</h5>
      <h1 class="igloos_facilities_title">Main Facilities</h1>
      <ul class="igloos_facilities_ul">
        <li class="igloos_facilities_li">
          <img
            class="igloos_facilities_li_img"
            src="./images/parking.png"
            alt="icon"
          />
          <p class="igloos_facilities_li_title">Private Parking</p>
          <p class="igloos_facilities_li_text">
            On-site secure parking, ensuring peace of mind at Hoteligloos.
          </p>
        </li>
        <li class="igloos_facilities_li">
          <img
            class="igloos_facilities_li_img"
            src="./images/wifi.png"
            alt="icon"
          />
          <p class="igloos_facilities_li_title">High Speed Wifi</p>
          <p class="igloos_facilities_li_text">
            Fast, reliable internet, enhancing your stay at Hoteligloos.
          </p>
        </li>
        <li class="igloos_facilities_li">
          <img
            class="igloos_facilities_li_img"
            src="./images/cafe.png"
            alt="icon"
          />
          <p class="igloos_facilities_li_title">Cafe</p>
          <p class="igloos_facilities_li_text">
            Sip and savor at our café with tasty snacks in a comfy setting.
          </p>
        </li>
        <li class="igloos_facilities_li">
          <img
            class="igloos_facilities_li_img"
            src="./images/room-service.png"
            alt="icon"
          />
          <p class="igloos_facilities_li_title">Room Service</p>
          <p class="igloos_facilities_li_text">
            Enjoy extra comfort with our simple room service for all your needs.
          </p>
        </li>
      </ul>
    </section>
    
                    </div>
                </div>
                
            </div>
            <Footer></Footer>

        </div>
    );
}