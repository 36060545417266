import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Booking from './components/Booking';
import Aboutus from './components/Aboutus';
import Contactus from './components/Contactus';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/contactus" element={<Contactus />} />
      </Routes>
    </div>
  );
}

export default App;
