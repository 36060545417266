import { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Footer from "./Footer";
const initialFieldValues = {
    bookingId: "00000000-0000-0000-0000-000000000000",
    reservationNo: "",
    checkinDate: new Date(),
    checkoutDate: new Date(),
    noOfNights: 1,
    adults: 1,
    kids: 0,
    noOfRooms: 0,
    guestName: "",
    guestEmail: "",
    guestMobile: "",
    roomPrices: 0,
    roomGST: 0,
    totalRoomPrice: 0,
    extraCharges: 0,
    foodBill: 0,
    discount: 0,
    discountAmount: 0,
    price: 0,
    gSTAmount: 0,
    gST: 12,
    finalPrice: 0,
    bookingDate: new Date(),
    bookingStatus: "BOOKED",
    paymentStatus: "PENDING",
    paymentMode: "CASH",
    source: "ONLINE",
    pendingAmount: 0,
    paidAmount: 0
};
export default function Home() {
    const minDateValue = new Date();
    const [rooms, setRooms] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [savePrice, setSavePrice] = useState(0);
    const [noOfRooms, setNoOfRooms] = useState(1);
    const [noOfNights, setNoOfNights] = useState(1);
    const [roomPrice, setRoomPrice] = useState(0);
    const [gstAmount, setGSTAmount] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [roomType, setRoomType] = useState(0);
    const [checkInDate, setCheckInDate] = useState(new Date());
    const [checkOutDate, setCheckOutDate] = useState(new Date());
    const [reservationStatus, setReservationStatus] = useState("");
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    const validate = () => {
        let temp = {};
        temp.guestName = values.guestName === "" ? false : true;
        temp.guestMobile = values.guestMobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (roomType === "NoRoom") {
            alert("Please select rooms");
        }
        else if (validate()) {
            const formData = new FormData()
            formData.append("bookingId", values.bookingId)
            formData.append("reservationNo", values.reservationNo)
            formData.append("checkinDate", moment(checkInDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
            formData.append("checkoutDate", moment(checkOutDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
            formData.append("noOfNights", noOfNights)
            formData.append("adults", values.adults)
            formData.append("kids", values.kids)
            formData.append("noOfRooms", noOfRooms)
            formData.append("guestName", values.guestName)
            formData.append("guestEmail", values.guestEmail)
            formData.append("guestMobile", values.guestMobile)
            formData.append("roomPrices", roomPrice)
            formData.append("roomGST", gstAmount)
            formData.append("totalRoomPrice", finalPrice)
            formData.append("extraCharges", values.extraCharges)
            formData.append("foodBill", values.foodBill)
            formData.append("discount", values.discount)
            formData.append("discountAmount", values.discountAmount)
            formData.append("price", finalPrice)
            formData.append("gSTAmount", gstAmount)
            formData.append("gST", values.gST)
            formData.append("finalPrice", finalPrice)
            formData.append("bookingDate", moment(values.bookingDate).format('YYYY-MM-DD'))
            formData.append("bookingStatus", values.bookingStatus)
            formData.append("paymentStatus", values.paymentStatus)
            formData.append("paymentMode", "PENDING")
            formData.append("source", values.source)
            formData.append("paidAmount", 0)
            formData.append("pendingAmount", finalPrice)
            addOrEdit(formData);
        }
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            'Content-Type': 'application/json',
        }
    }
    const addOrEdit = (formData) => {
        if (formData.get('bookingId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        resetForm();
                        setReservationStatus(res.data.data)
                    }
                    else {
                        setReservationStatus("ERROR IN BOOKING CONFIRMATION");
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
        setSavePrice(0);
        setCheckInDate(new Date())
        setCheckOutDate(new Date())
        setFinalPrice(0)
        setGSTAmount(0)
        setNoOfNights(1)
        setNoOfRooms(1)
        setRoomPrice(0)
        setSavePrice(0)
        setReservationStatus("")
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post("https://dchoteligloos.azurewebsites.net/api/Booking/OnlineCreate", newrecord, { ...headerconfig })
        };
    };
    const handleRoomType = (e) => {
        var roomType = e.target.value;
        setRoomType(roomType)
        var checkin = new Date(checkInDate);
        var checkout = new Date(checkOutDate);
        if (checkOutDate <= checkInDate) {
            alert("Checkout should be more than 1 day from check in");
        }
        else {
            const diffTime = Math.abs(checkout - checkin);
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var temproomprice = 0;
            if (roomType === "Executive") {
                temproomprice = 1800;
            }
            else if (roomType === "Premium") {
                temproomprice = 2500;
            }
            else if (roomType === "Suite") {
                temproomprice = 4000;
            }
            else {
                alert("Please check the room type");
            }
            var roomprice = parseFloat(temproomprice * nights * noOfRooms);
            var gst = parseFloat((roomprice * 12) / 100);
            setRoomPrice(parseFloat(roomprice));
            var price = parseFloat(roomprice + gst);
            var gSTAmount = parseFloat((price * 12) / 100);
            setGSTAmount(Math.round(parseFloat((price * 12) / 100)));
            setFinalPrice(Math.round(price + gSTAmount));
        }
    };
    const handleNoOfRooms = (e) => {
        var noOfRooms = e.target.value;
        setNoOfRooms(noOfRooms)
        var checkin = new Date(checkInDate);
        var checkout = new Date(checkOutDate);
        if (checkOutDate <= checkInDate) {
            alert("Checkout should be more than 1 day from check in");
        }
        else {
            const diffTime = Math.abs(checkout - checkin);
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var temproomprice = 0;
            if (roomType === "Executive") {
                temproomprice = 1800;
            }
            else if (roomType === "Premium") {
                temproomprice = 2500;
            }
            else if (roomType === "Suite") {
                temproomprice = 4000;
            }
            else {
                alert("Please check the room type");
            }
            var roomprice = parseFloat(temproomprice * nights * noOfRooms);
            var gst = parseFloat((roomprice * 12) / 100);
            setRoomPrice(parseFloat(roomprice));
            var price = parseFloat(roomprice + gst);
            var gSTAmount = parseFloat((price * 12) / 100);
            setGSTAmount(Math.round(parseFloat((price * 12) / 100)));
            setFinalPrice(Math.round(price + gSTAmount));
        }
    };
    return (
        <div id="main-wrapper">
            <Header></Header>
            <section className="page-header-dark bg-secondary">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h1 className="d-flex flex-wrap align-items-center"></h1>
                        </div>
                    </div>
                </div>
            </section>
            <div id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
                               
                                <hr className="mb-4" />
                                <h2 id="choose-room" className="text-6 mb-4 mt-2">Rooms</h2>
                                <div className="row g-4">
                                    <div className="col-12 col-md-5">
                                        <Carousel>
                                            <div>
                                                <img src="/images/rooms/executive/1.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/executive/2.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/executive/3.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/executive/4.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/executive/5.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                        </Carousel>
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <h4 className="text-5">Executive Room</h4>
                                        <ul className="list-inline mb-2">
                                            <li className="list-inline-item"><span className="me-1 text-black-50"><i className="fas fa-bed" /></span> King Size Bed</li>
                                        </ul>
                                        <div className="row text-1 mb-3">
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Free WiFi</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Television</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Shower</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Tea Maker</div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="text-dark text-7 lh-1 fw-500 me-2 me-lg-3">INR 1800</div>
                                            <div className="d-block text-4 text-black-50 me-2 me-lg-3"><del className="d-block">INR 2000</del></div>
                                            <div className="text-success text-3 me-2 me-lg-3">200 Off!</div>
                                            <span className="text-black-50">1 Room/Night</span> </div>
                                        <div className="d-flex align-items-center mt-3"> <a href="#" data-bs-toggle="modal" data-bs-target="#cancellation-policy">Cancellation Policy</a></div>
                                    </div>
                                </div>
                                <hr className="my-4" />
                                <div className="row g-4">
                                    <div className="col-12 col-md-5">
                                        <Carousel>
                                            <div>
                                                <img src="/images/rooms/premium/4.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/premium/2.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/premium/3.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/premium/4.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/premium/5.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                        </Carousel>
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <h4 className="text-5">Premium Room</h4>
                                        <ul className="list-inline mb-2">
                                            <li className="list-inline-item"><span className="me-1 text-black-50"><i className="fas fa-bed" /></span> King Size Bed</li>
                                        </ul>
                                        <div className="row text-1 mb-3">
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Air Conditioning</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Free WiFi</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Television</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Tea Maker</div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="text-dark text-7 lh-1 fw-500 me-2 me-lg-3">INR 2500</div>
                                            <div className="d-block text-4 text-black-50 me-2 me-lg-3"><del className="d-block">INR3000</del></div>
                                            <div className="text-success text-3 me-2 me-lg-3">500 Off!</div>
                                            <span className="text-black-50">1 Room/Night</span> </div>
                                        <div className="d-flex align-items-center mt-3"> <a href="#" data-bs-toggle="modal" data-bs-target="#cancellation-policy">Cancellation Policy</a></div>
                                    </div>
                                </div>
                                <hr className="my-4" />
                                <div className="row g-4">
                                    <div className="col-12 col-md-5">
                                        <Carousel>
                                            <div>
                                                <img src="/images/rooms/suite/8.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/suite/2.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/suite/3.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/suite/4.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                            <div>
                                                <img src="/images/rooms/suite/5.jpg" className="img-fluid rounded align-top" />
                                            </div>
                                        </Carousel>
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <h4 className="text-5">Suite Room</h4>
                                        <ul className="list-inline mb-2">
                                            <li className="list-inline-item"><span className="me-1 text-black-50"><i className="fas fa-bed" /></span> Triple Size Bed</li>
                                        </ul>
                                        <div className="row text-1 mb-3">
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Air Conditioning</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Free WiFi</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Television</div>
                                            <div className="col-6 col-xl-4"><span className="text-success me-1"><i className="fas fa-check" /></span>Tea Maker</div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="text-dark text-7 lh-1 fw-500 me-2 me-lg-3">INR 4000</div>
                                            <div className="d-block text-4 text-black-50 me-2 me-lg-3"><del className="d-block">INR 4500</del></div>
                                            <div className="text-success text-3 me-2 me-lg-3">500Off!</div>
                                            <span className="text-black-50">1 Room/Night</span> </div>
                                        <div className="d-flex align-items-center mt-3"> <a href="#" data-bs-toggle="modal" data-bs-target="#cancellation-policy">Cancellation Policy</a></div>
                                    </div>
                                </div>
                                {/* Cancellation Policy Modal */}
                                <div id="cancellation-policy" className="modal fade" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Cancellation Policy</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="modal-body">
                                                <ul>
                                                    <li>Non-Refundable on cancellation or No Show</li>
                                                    <li>Any Add-on charges are Non-Refundable.</li>
                                                    <li>You can not change the Check-in or Check-out date.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4" />
                                {/* Amenities end */}
                                <hr className="my-4" />
                                <h2 id="hotel-policy" className="text-6 mb-3 mt-2">Hotel Policy</h2>
                                <p>The standard check-in time is 10:00 AM and the standard check-out time is 09:00 AM. Early check-in or late check-out is strictly subjected to availability and may be chargeable by the hotel. Any early check-in or late check-out request must be directed and reconfirmed with hotel directly, Accommodation, 24 hours House Keeping, Hot and Cold water available, Internet, Telephone.</p>
                                <p>Hotel Policy: Most hotels do not allow unmarried/unrelated couples to check-in. This is at the full discretion of the hotel management. No refund would be applicable in case the hotel denies check-in under such circumstances., Most hotels do not allow same city/local ID Proofs for check-in. Kindly check with your hotel about the same before checking in. This is at full discretion of the hotel management. No refund would be applicable in case the hotel denies check-in under such circumstances.</p>
                                {/* Hotel Policy end */}
                            </div>
                        </div>
                        {/* Middle Panel End*/}
                        {/* Side Panel
  ============================================= */}
                        <aside className="col-lg-4 mt-4 mt-lg-0">
                            <div className="bg-white shadow-md rounded p-3 sticky-top">
                                <p className="reviews text-center"> <span className="reviews-score rounded fw-600 text-white px-2 py-1">5.0</span> <span className="fw-600">Excellent Reviews</span> <span className="text-black-50"></span> </p>
                                <hr className="mx-n3" />
                                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                    <div className="row g-3">
                                        <div className="col-lg-12">
                                            <input type="text" value={values.guestName} name="guestName" onChange={handleInputChange} className={"form-control" + applyErrorClass('guestName')} placeholder="Please Enter Guest Name" />
                                            {errors.guestName === false ? (<div className="validationerror">Please enter guest name </div>) : ('')}
                                        </div>
                                        <div className="col-lg-12">
                                            <input type="text" value={values.guestMobile} name="guestMobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('guestMobile')} placeholder="Please Enter Guest Mobile" />
                                            {errors.guestMobile === false ? (<div className="validationerror">Please enter guest mobile </div>) : ('')}
                                        </div>
                                        <div className="col-lg-12">
                                            <input type="text" value={values.guestEmail} name="guestEmail" onChange={handleInputChange} className="form-control" placeholder="Please Enter Guest Email" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="position-relative">
                                                <DatePicker selected={checkInDate} minDate={new Date()} onChange={(date) => setCheckInDate(date)} className="form-control" />
                                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="position-relative">
                                                <DatePicker selected={checkOutDate} minDate={new Date()} onChange={(date) => setCheckOutDate(date)} className="form-control" />
                                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <select className="form-select" name="roomType" value={roomType} onChange={handleRoomType}>
                                                <option value={"NoRoom"}>Select Room</option>
                                                <option value={"Executive"}>Executive Room</option>
                                                <option value={"Premium"}>Premium Room</option>
                                                <option value={"Suite"}>Suite Room</option>
                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <select className="form-select" value={noOfRooms} name="noOfRooms" onChange={handleNoOfRooms}>
                                                <option value={1}>1 Room</option>
                                                <option value={2}>2 Rooms</option>
                                                <option value={3}>3 Rooms</option>
                                                <option value={4}>4 Rooms</option>
                                                <option value={5}>5 Rooms</option>
                                                <option value={6}>6 Rooms</option>
                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <select className="form-select" value={values.adults} name="adults" onChange={handleInputChange}>
                                                <option value={0}>Adults</option>
                                                <option value={1}>1 Adult</option>
                                                <option value={2}>2 Adults</option>
                                                <option value={3}>3 Adults</option>
                                                <option value={4}>4 Adults</option>
                                                <option value={5}>5 Adults</option>
                                                <option value={6}>6 Adults</option>
                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <select className="form-select" name="kids" value={values.kids} onChange={handleInputChange}>
                                                <option value={0}>Kids</option>
                                                <option value={1}>1 Kid</option>
                                                <option value={2}>2 Kids</option>
                                                <option value={3}>3 Kids</option>
                                                <option value={4}>4 Kids</option>
                                                <option value={5}>5 Kids</option>
                                                <option value={6}>6 Kids</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center my-4">
                                        <div className="text-black-50 lh-1 fw-500 me-2 me-lg-3">Room Price  {noOfNights} Night(s)</div>
                                        <div className="text-dark text-4 ms-auto">INR {roomPrice}</div>
                                    </div>
                                    <div className="d-flex align-items-center my-4">
                                        <div className="text-black-50 lh-1 fw-500 me-2 me-lg-3">GST(12%)</div>
                                        <div className="text-dark text-4 ms-auto">INR {gstAmount}</div>
                                    </div>
                                    <div className="d-flex align-items-center my-4">
                                        <div className="text-black-50 lh-1 fw-500 me-2 me-lg-3">Final Price</div>
                                        <div className="text-dark text-4 ms-auto">INR {finalPrice}</div>
                                    </div>
                                    <p className="text-center mt-3 mb-1"><span className="text-uppercase fw-700" style={{color:"#008000"}}>{reservationStatus }</span></p>
                                    <div className="d-grid">
                                        <button className="btn btn-primary" type="submit">BOOK NOW AND PAY AT VENUE</button>
                                    </div>
                                    
                                </form>
                            </div>
                        </aside>
                        {/* Side Panel End */}
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}