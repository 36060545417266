import { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function Footer() {
    return (
        <footer id="footer">
  <section className="section bg-white shadow-md pt-4 pb-3">
  <div className="container mt-4">
    <div className="row g-4">
      <div className="col-md-4">
        <p>Payment</p>
        <ul className="payments-types">
          <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/visa.png" alt="visa" title="Visa" /></a></li>
          <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/discover.png" alt="discover" title="Discover" /></a></li>
          <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/paypal.png" alt="paypal" title="PayPal" /></a></li>
          <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/american.png" alt="american express" title="American Express" /></a></li>
          <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/mastercard.png" alt="discover" title="Discover" /></a></li>
        </ul>
      </div>
      <div className="col-md-4">
        <p></p>
        <div className="input-group newsletter">
          
          
        </div>
      </div>
      <div className="col-md-4 d-flex align-items-md-end flex-column">
        <p>Keep in touch</p>
        <ul className="social-icons">
          <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/hoteligloos" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
          <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/hoteligloos" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
          <li className="social-icons-instagram"><a data-bs-toggle="tooltip" href="http://www.instagram.com/hoteligloos" target="_blank" title="Instagram"><i className="fab fa-instagram" /></a></li>
        </ul>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="footer-copyright">
      <p className="copyright-text">Copyright © 2023 <Link to="/">Hotel Igloos</Link>. All Rights Reserved.</p>
    </div>
  </div>
  </section>
</footer>

      
    );
}