import { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Footer from "./Footer";
const initialFieldValues = {
    bookingId: "00000000-0000-0000-0000-000000000000",
    reservationNo: "",
    checkinDate: new Date(),
    checkoutDate: new Date(),
    noOfNights: 1,
    adults: 1,
    kids: 0,
    noOfRooms: 0,
    guestName: "",
    guestEmail: "",
    guestMobile: "",
    roomPrices: 0,
    roomGST: 0,
    totalRoomPrice: 0,
    extraCharges: 0,
    foodBill: 0,
    discount: 0,
    discountAmount: 0,
    price: 0,
    gSTAmount: 0,
    gST: 12,
    finalPrice: 0,
    bookingDate: new Date(),
    bookingStatus: "BOOKED",
    paymentStatus: "PENDING",
    paymentMode: "CASH",
    source: "WALKIN",
    pendingAmount: 0,
    paidAmount: 0
};
export default function Contactus() {
    return (
        <div id="main-wrapper">
            <Header></Header>
            <section className="page-header-dark bg-secondary">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h1 className="d-flex flex-wrap align-items-center"></h1>
                        </div>
                    </div>
                </div>
            </section>
            <div id="content">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="bg-white shadow-md rounded h-100 p-3">
                                <iframe className="h-100 w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30457.74223408909!2d78.54649483476561!3d17.401333299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9f1e6f33fbeb%3A0xb75cbeaf371c2427!2sCollection%20O%20Hotel%20Igloos!5e0!3m2!1sen!2sin!4v1702880950560!5m2!1sen!2sin" allowFullScreen />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="bg-white shadow-md rounded p-4">
                                <h2 className="text-6 mb-4">Get in touch</h2>
                                <hr className="mx-n4 mb-4" />
                                <p className="text-3">For Customer Support and Query</p>
                                <div className="featured-box style-1">
                                    <div className="featured-box-icon text-primary"> <i className="fas fa-map-marker-alt" /></div>
                                    <h3>HOTEL IGLOOS</h3>
                                    <p>SURAAG HOTELS AND RESORTS,<br />
                                    3-35, HANUMAN SAI NAGAR, BESIDE SRI KRISHNA THEATER<br />
                                    MAIN ROAD, UPPAL KHALSA, UPPAL,MEDCHAL<br />
                                    Hyderabad, India, Telangana </p>
                                </div>
                                <div className="featured-box style-1">
                                    <div className="featured-box-icon text-primary"> <i className="fas fa-phone" /> </div>
                                    <h3>Telephone</h3>
                                    <p>+91 40 4950 5357</p>
                                </div>
                                <div className="featured-box style-1">
                                    <div className="featured-box-icon text-primary"> <i className="fas fa-envelope" /> </div>
                                    <h3>For Inquiries</h3>
                                    <p>hoteligloos@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<Footer></Footer>
        </div>
    );
}