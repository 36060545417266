import { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Footer from "./Footer";
const initialFieldValues = {
    bookingId: "00000000-0000-0000-0000-000000000000",
    reservationNo: "",
    checkinDate: new Date(),
    checkoutDate: new Date(),
    noOfNights: 1,
    adults: 1,
    kids: 0,
    noOfRooms: 0,
    guestName: "",
    guestEmail: "",
    guestMobile: "",
    roomPrices: 0,
    roomGST: 0,
    totalRoomPrice: 0,
    extraCharges: 0,
    foodBill: 0,
    discount: 0,
    discountAmount: 0,
    price: 0,
    gSTAmount: 0,
    gST: 12,
    finalPrice: 0,
    bookingDate: new Date(),
    bookingStatus: "BOOKED",
    paymentStatus: "PENDING",
    paymentMode: "CASH",
    source: "WALKIN",
    pendingAmount: 0,
    paidAmount: 0
};
export default function Aboutus() {
    return (
        <div id="main-wrapper">
            <Header></Header>
            <section className="page-header-dark bg-secondary">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h1 className="d-flex flex-wrap align-items-center"></h1>
                        </div>
                    </div>
                </div>
            </section>
            <div id="content">
                <div className="container">
                    <div className="bg-white shadow-md rounded p-4">
                        <h2 className="text-6">WELCOME TO HOTEL IGLOOS</h2>
                        <span>Hotel Igloos, where luxury meets heritage and hospitality! Nestled in the heart of India, we pride ourselves on offering an unforgettable experience that blends modern comfort with traditional Indian warmth.
                            Get ready for personalized service and cool vacations. Our team wants to make your time with us super special, whether it's helping you out or planning fun holiday deals.</span>
                        <p>Offering a warm and inviting atmosphere, Hoteligloos is dedicated to ensuring your stay is memorable. Our passion for affordable comfort creates a cozy retreat for every guest. Explore a unique blend of affordability and charm at Hoteligloos, where simplicity meets hospitality. Nestled as <strong> SURAAG HOTELS AND RESORTS</strong> near Hanuman Sai Nagar, Beside Sri KRISHNA Theater.</p>
                        <br></br>
                        <h4>Location</h4>
                        <span>Situated in a picturesque setting surrounded by natural beauty, our lodge offers a tranquil escape from the hustle and bustle of everyday life. Whether you're a traveler seeking adventure, a family on vacation, or a couple looking for a romantic getaway, our strategic location ensures easy access to metro, bustation, railway station and airport.</span>
                        <br></br>
                        <br></br>
                        <h4>Accommodation</h4>
                        <span>Our hotel boasts a diverse range of accommodations designed to cater to every guest's needs. From spacious rooms adorned with elegant décor to luxurious suites offering panoramic views of the breathtaking surroundings, each space is meticulously crafted to provide comfort and relaxation..</span>
                        <br></br>
                        <br></br>
                        <h4>Services and Amenities</h4>
                        <span>We take pride in offering exceptional services and amenities to ensure a delightful stay. Whether it's our round-the-clock concierge, rejuvenating spa treatments, well-equipped fitness center, or our personalized excursion planning, every aspect of your experience is tailored to exceed expectations.</span>
                        <br></br>
                        <br></br>
                        <h4>Our Team</h4>
                        <span>Our dedicated team is the heart and soul of Hotel Igloos. Comprising passionate individuals who embody the essence of Indian hospitality, they are committed to making your stay memorable by providing attentive and personalized service.</span>
                        <p>Come and experience the essence of India's hospitality at Hotel Igloos. Whether it's for leisure or business, let us be your gateway to an unforgettable journey filled with warmth, comfort, and cultural immersion.</p>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}